
<div class="d-flex flex-align-center ml-2">
  <b-form-checkbox
    v-model="autoRefreshLoc"
    class="ml-auto float-right my-auto"
    title="AutoRefresh"
  ></b-form-checkbox>
  <b-button
    class="m-1"
    variant="secondary"
    size="sm"
    title="Auto Refresh All bots"
    @click="allRefreshFull"
  >
    <RefreshIcon :size="16" />
  </b-button>
</div>
