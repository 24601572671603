
<div>
  <form ref="form" novalidate @submit.stop.prevent="handleSubmit" @reset="handleReset">
    <b-form-group label="Bot Name" label-for="name-input">
      <b-form-input
        id="name-input"
        v-model="auth.botName"
        placeholder="Bot Name"
        @keydown.enter.native="handleOk"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      :state="urlState"
      label="API Url"
      label-for="url-input"
      invalid-feedback="API Url required"
    >
      <b-form-input
        id="url-input"
        v-model="auth.url"
        :state="urlState"
        required
        @keydown.enter.native="handleOk"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      :state="nameState"
      label="Username"
      label-for="username-input"
      invalid-feedback="Name and Password are required."
    >
      <b-form-input
        id="username-input"
        v-model="auth.username"
        required
        placeholder="Freqtrader"
        @keydown.enter.native="handleOk"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      label="Password"
      label-for="password-input"
      invalid-feedback="Invalid Password"
      :state="pwdState"
    >
      <b-form-input
        id="password-input"
        v-model="auth.password"
        required
        type="password"
        @keydown.enter.native="handleOk"
      ></b-form-input>
    </b-form-group>
    <div>
      <b-alert v-if="errorMessage" class="alert-wrap" show variant="warning">
        {{ errorMessage }}
        <br />
        <span v-if="errorMessageCORS">
          Please also check your bot's CORS configuration:
          <a href="https://www.freqtrade.io/en/latest/rest-api/#cors"
            >Freqtrade CORS documentation</a
          ></span
        >
      </b-alert>
    </div>
    <div v-if="inModal === false" class="float-right">
      <b-button class="mr-2" type="reset" variant="danger">Reset</b-button>
      <b-button type="submit" variant="primary">Submit</b-button>
    </div>
  </form>
</div>
