
<form class="d-flex" @submit.prevent="save">
  <b-form-input
    v-model="newName"
    size="sm"
    class="w-100"
    placeholder="Bot name"
    style="border-style: solid; border-width: 1px"
    autofocus
  />

  <div class="d-flex ml-2">
    <b-button type="submit" size="sm" title="Save">
      <CheckIcon :size="16" />
    </b-button>

    <b-button class="ml-1" size="sm" title="Cancel" @click="$emit('cancelled')">
      <CloseIcon :size="16" />
    </b-button>
  </div>
</form>
