
<div class="home">
  <div class="container col-12 col-sm-6 col-lg-4">
    <bot-list />
  </div>
  <hr />
  <!-- <img alt="Freqtrade logo" src="../assets/freqtrade-logo.png" width="450px" class="my-5" /> -->
  <div alt="Freqtrade logo" class="logo-svg my-5 mx-auto" />
  <div>
    <h1>Welcome to the Freqtrade UI</h1>
  </div>
  <div>This page allows you to control your trading bot.</div>
  <br />
  <p>
    If you need any help, please refer to the
    <a href="https://www.freqtrade.io/en/latest/">Freqtrade Documentation</a>.
  </p>

  <p>Have fun - <i>wishes you the Freqtrade team</i></p>
</div>
