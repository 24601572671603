
<div>
  <b-nav-item-dropdown
    v-if="!simple"
    id="my-nav-dropdown"
    text="Theme"
    toggle-class="nav-link-custom"
    right
    lazy
  >
    <b-dropdown-item v-if="themeList.length === 0">
      <b-spinner small></b-spinner> Loading Themes...
    </b-dropdown-item>

    <!-- TODO Add  v-b-tooltip.hover.right=="{ variant: 'className' }" for tooltip class rendered from bootswatch-->
    <b-dropdown-item-button
      v-for="(theme, key) in themeList"
      :key="key"
      v-b-tooltip.hover.right
      :active="activeTheme === theme.name"
      :title="theme.description"
      :name="theme.name"
      @click="handleClick"
      >{{ theme.name }}{{ theme.dark ? ' [dark]' : '' }}</b-dropdown-item-button
    >
  </b-nav-item-dropdown>
  <b-link v-else variant="outline-primary" class="nav-link" @click="toggleNight">
    <ThemeLightDark :size="16" />
  </b-link>
</div>
