
<footer class="d-md-none">
  <!-- Only visible on xs (phone) viewport! -->
  <hr class="my-0" />
  <div class="d-flex flex-align-center justify-content-center">
    <router-link v-if="!canRunBacktest" class="nav-link navbar-nav" to="/open_trades">
      <OpenTradesIcon />
      Trades
    </router-link>
    <router-link v-if="!canRunBacktest" class="nav-link navbar-nav" to="/trade_history">
      <ClosedTradesIcon />
      History
    </router-link>
    <router-link v-if="!canRunBacktest" class="nav-link navbar-nav" to="/pairlist">
      <PairListIcon />
      Pairlist
    </router-link>
    <router-link v-if="!canRunBacktest" class="nav-link navbar-nav" to="/balance">
      <BalanceIcon />
      Balance
    </router-link>
    <router-link v-if="!canRunBacktest" class="nav-link navbar-nav" to="/dashboard">
      <DashboardIcon />
      Dashboard
    </router-link>
  </div>
</footer>
